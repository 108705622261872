<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{ form.shopName }}</span>
        <span class="mr-20">订单编号：{{ form.orderId }}</span>
        <span class="mr-20">订单类型：{{ form.orderSourceName }}</span>
        <span class="mr-20 primary-color"
          >订单状态：{{ form.orderStatusName }}</span
        >
        <span class="mr-20">备注：{{ form.remarks }}</span>
        <span class="mr-20">创建时间：{{ form.orderTime }}</span>
        <p class="mt-10" v-if="form.memberId">
          <span class="mr-20">客户姓名：{{ form.memberName }}</span>
          <span class="mr-20">手机号：{{ form.mobile }}</span>
        </p>
        <p class="mt-10">
          <span class="mr-20">订单金额：{{ form.amount }}</span>
          <span class="mr-20">优惠金额：{{ form.amountDiscount }}</span>
          <span class="mr-20">实收金额：{{ form.amountPay }}</span>
          <span class="mr-20">收款方式：{{ form.payMethodName }}</span>
          <span class="mr-20"
            >收款明细：<template v-for="item in form.payItems">
              {{ item.payMethodName }}: {{ item.amountPaid }} RM
            </template></span
          >
        </p>
      </div>
      <template slot="btns">
        <a-button
          class="mr-10"
          type="primary"
          icon="money-collect"
          v-if="form.orderStatus === 1 && this.paramsId"
          @click="handlePay"
          >收款</a-button
        >
      </template>
      <div slot="content">
        <a-card title="会员充值">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 14 }"
          >
            <!--            会员卡号/手机号、充值金额、折扣规则（可自定义）、支付方式（现金、扫码）、立即充值、取消-->
            <a-row>
              <a-col :span="12">
                <a-row>
                  <a-col>
                    <a-form-model-item label="会员卡号" prop="mobile">
                      <a-input-search
                        placeholder="请输入会员卡号/手机号"
                        allowClear
                        v-model.trim="form.mobile"
                        :disabled="isDetail"
                        @change="handleSearch"
                        @pressEnter="handleSearch"
                      >
                      </a-input-search>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="充值金额" prop="amount">
                      <a-input-number
                        v-model="form.amount"
                        size="large"
                        :maxLength="10"
                        :default-value="100"
                        :min="0"
                        :max="9999999999"
                        :disabled="isDetail"
                        allowClear
                        @change="handleChangeAmount"
                      />
                      <span class="ml-20"> RM </span>
                      <div
                        class="mt-10"
                        v-if="rechargeRuleList.length > 0 && !isDetail"
                      >
                        <a-radio-group
                          v-model="form.amount"
                          button-style="solid"
                          @change="handleChangeAmount"
                        >
                          <a-radio-button
                            class="mt-10 mr-10"
                            :value="item.amount"
                            v-for="item in rechargeRuleList"
                            :key="item.shopRechargeRuleId"
                          >
                            {{ item.amount }} RM
                          </a-radio-button>
                        </a-radio-group>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="充值规则" prop="discountWay">
                      <a-select
                        v-model="form.discountWay"
                        :disabled="isDetail"
                        allowClear
                        @change="handleChangeRule"
                      >
                        <a-select-option :value="0"> 无 </a-select-option>
                        <a-select-option
                          v-for="item in rechargeRuleList"
                          :key="item.shopRechargeRuleId"
                          >{{ item.title }}</a-select-option
                        >
                      </a-select>
                      <div class="line-break">
                        实付金额：<span class="primary-color">
                          {{
                            form.amount - CouponSum > 0
                              ? form.amount - CouponSum
                              : 0
                          }}
                          RM</span
                        >， 赠送金额：<span class="primary-color"
                          >{{ form.amountDiscount }} RM</span
                        >
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="优惠券" prop="coupon">
                      <div class="couponCard">
                        <template v-for="item in currentCouponRecords">
                          <div
                            class="couponCardItem isChecked"
                            :class="{
                              'is-used': !!item.used,
                              'type-1': +item.types === 1,
                              'type-2': +item.types === 2,
                              'type-3': +item.types === 3,
                              'type-4': +item.types === 4,
                              'type-3': +item.types === 5,
                            }"
                            @click="handleChooseCurrentCoupon(item)"
                          >
                            <div class="yuan"></div>
                            <div class="left">
                              <div class="l-inner">
                                <div class="l1">
                                  ￥<span
                                    style="font-weight: bold; line-height: 1"
                                    >{{ item.cash }}</span
                                  >
                                </div>
                                <div class="l2" style="margin-top: -8px">
                                  {{
                                    item.conditioncash > 0
                                      ? "满 " + item.conditioncash + " RM可用"
                                      : "无金额限制"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="right">
                              <!-- <div class="name">{{ item.couponName }}</div> -->
                              <div style="display: flex; flex-direction: row">
                                <div class="ccc">
                                  {{ dictCouponType(item.types) }}
                                </div>
                                <div class="Title_name">
                                  {{ item.couponName }}
                                </div>
                              </div>
                              <div class="indate">
                                {{ computedIndate(item) }}
                              </div>
                              <div class="isShare">
                                {{
                                  item.sharecnt > 1
                                    ? "可叠加(" + item.sharecnt + "次)"
                                    : "不可叠加"
                                }}
                              </div>
                            </div>
                            <!-- <div class="float-text">
                                <div class="aaa">
                                  <div class="bbb">
                                    <span>{{ dictCouponType(item.types) }}</span>
                                  </div>
                                </div>
                              </div> -->
                            <div class="checked-ouline" v-if="!!item.checked">
                              <a-icon type="check-circle" />
                            </div>
                          </div>
                        </template>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="备注" prop="remarks">
                      <a-input
                        v-model="form.remarks"
                        :auto-size="{ minRows: 4, maxRows: 6 }"
                        allowClear
                        :maxLength="100"
                        type="textarea"
                        :disabled="isDetail"
                        allowClear
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-col>
              <a-col span="10" push="1" v-if="!paramsId">
                <a-descriptions title="会员信息" :column="1">
                  <a-descriptions-item label="姓名">{{
                    currentMember.realName || "-"
                  }}</a-descriptions-item>
                  <a-descriptions-item label="手机号">{{
                    currentMember.mobile || "-"
                  }}</a-descriptions-item>
                  <a-descriptions-item label="会员卡号">
                    {{ currentMember.memberCode || "-" }}</a-descriptions-item
                  >
                  <a-descriptions-item label="当前余额">
                    {{ currentMember.amount || "0" }} RM</a-descriptions-item
                  >
                </a-descriptions>

                <a-statistic
                  title="充值后余额"
                  :precision="2"
                  :value="amountAfterRecharge"
                  style="word-break: break-all"
                >
                  <template #suffix> RM </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>

    <!-- 收款弹出窗 开始 -->
    <payment-modal
      v-model="payVisible"
      :data="form"
      @on-ok="handlePayOk"
      @on-cancel="handleCancel"
    ></payment-modal>
    <!-- 收款弹出窗 结束 -->
  </div>
</template>

<script>
import moment from 'moment';
import { rechargeInfo, rechargeSubmit, rechargeRules } from '@/api/recharge';
import { getByCodeOrMobile } from '@/api/member';
import FormFrame from '@/components/frame/FormFrame.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import formMixin from '@/mixin/formMixin';
import { deepClone } from '@/libs/utils';
import { getRechageCouponlistByRuleId, getMemberCouponUsedByMemberId, checkHoliday } from '@/api/coupon';

export default {
  name: 'MemberForm',
  mixins: [formMixin],
  components: {
    FormFrame,
    PaymentModal,
  },
  props: {},
  data() {
    return {
      currentMember: {},
      payVisible: false,
      actionData: {},
      paymentMap: new Map([
        [1, '微信'],
        [2, '支付宝'],
        [3, '现金'],
        [4, '银联'],
      ]),
      formCopy: {},
      form: {
        name: '',
        mobile: '',
        discountWay: 0,
        amountDiscount: 0,
        amount: 1,
        payType: 1,
        remarks: '',
        couponids: '',
        tempAmount: 0
      },
      rules: {
        amount: [
          {
            required: true,
            message: '请输入充值金额',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入',
          },
        ],
        mobile: [
          {
            required: true,
            message: '请输入',
          },
        ],
        discountWay: [
          {
            required: true,
            message: '请选择折扣规则',
          },
        ],
      },
      rechargeRuleList: [],
      currentCouponRecords: [],
      currentCouponRecordsBack: [],
      selectCoupons: [],
      CouponSum: 0,
      isHoliday: false,
    };
  },
  computed: {
    amountAfterRecharge() {
      return (
        (this.currentMember.amount || 0)
        + (this.form.amount || 0)
        + (this.form.amountDiscount || 0)
      ).toFixed(2);
    },
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
    this.getcheckHoliday();
    this.getRechargeRuleList();
  },
  mounted() {},
  destroyed() {},
  methods: {
    getcheckHoliday() {
      checkHoliday().then((res) => {
        if (res.length > 0) this.isHoliday = true;
        else this.isHoliday = false;
      });
    },
    getData(id) {
      rechargeInfo(id).then((res) => {
        this.form = res;
      });
    },
    getRechargeRuleList() {
      rechargeRules().then((data) => {
        this.rechargeRuleList = data;
      });
    },
    handleReset() {
      this.$refs.form.resetFields();
    },

    handleChangeAmount() {
      const ruleItem = this.getNearestRule(this.form.amount);
      this.form.discountWay = ruleItem.shopRechargeRuleId;
      this.form.amountDiscount = ruleItem.amountDiscount;
      this.form.tempAmount = ruleItem.tempAmount;
    },

    getNearestRule(value = 0) {
      let temp = 0; // 当前满足条件到区间值
      const result = {
        shopRechargeRuleId: 0,
        amountDiscount: 0,
        tempAmount: 0,
      };
      this.rechargeRuleList.forEach((item) => {
        if (item.amount <= value && item.amount >= temp) {
          temp = item.amount;
          result.amountDiscount = item.amountDiscount;
          result.shopRechargeRuleId = item.shopRechargeRuleId;
          result.tempAmount = item.amount;
        }
      });
      return result;
    },
    selectRechargeCouponByRuleId(ruleId) {
      const that = this;
      this.currentCouponRecords = [];
      getRechageCouponlistByRuleId(ruleId).then((res) => {
        that.currentCouponRecordsBack = res;
        this.filterMemberCoupon();
        console.log(
          `selectRechargeCouponByRuleId size:${that.currentCouponRecords.length}`
        );
      });
    },
    handleChangeRule(ruleId) {
      const data = this.rechargeRuleList.find(
        (item) => item.shopRechargeRuleId === ruleId
      );
      this.form.discountWay = data ? data.shopRechargeRuleId : 0;
      this.form.amountDiscount = data ? data.amountDiscount : 0;
      this.form.tempAmount = data ? data.amount : 0;
    },

    // 提交支付订单
    handleSave() {
      if (Number(this.form.amount) + Number(this.CouponSum) < Number(this.form.tempAmount)) {
        this.$message.error('输入金额不满足优惠条件，请核对');
        return;
      }
      const params = {
        memberId: this.currentMember.memberId,
        // amount: this.form.amount,
        amount: Number(this.form.amount) - Number(this.CouponSum),
        amountDiscount:
          Number(this.form.amountDiscount) + Number(this.CouponSum), // 优惠金额
        amountPay: Number(this.form.amount) - Number(this.CouponSum), // 应付金额
        discountWay: this.form.discountWay, // 优惠方式 0:无优惠 根据订单类型 优惠方式不一样
        discountWayRamarks: '',
        mobile: this.form.mobile,
        remarks: this.form.remarks,
        couponId: this.form.couponids,
      };
      console.log(params);
      rechargeSubmit(params)
        .then((data) => {
          this.formCopy = deepClone(this.form);
          this.form = data;
          this.handlePay();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMemebrCouponListRequest(data) {
      if (data === undefined || data == null || data.memberId === undefined) return;
      const that = this;
      const _param = {
        memberid: data.memberId,
      };
      getMemberCouponUsedByMemberId(_param).then((res) => {
        that.currentCouponRecordsBack = res;
        that.filterMemberCoupon();
      });
    },
    handleSearch(e) {
      this.currentCouponRecords = [];
      this.currentCouponRecordsBack = [];
      this.selectCoupons = [];
      this.CouponSum = 0;
      const searchText = e.target.value;
      if (searchText.length < 8) {
        return;
      }

      this.$store.commit('setDisableLoading', true);
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
        this.getMemebrCouponListRequest(data);
      });
    },

    // 创建充值订单
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },

    // 收款
    handlePay() {
      this.payVisible = true;
    },
    // 支付成功
    handlePayOk() {
      if (this.paramsId) {
        this.getData(this.paramsId);
      } else {
        this.handleBack();
      }
    },
    // 取消支付
    handleCancel() {
      if (!this.paramsId) {
        this.form = this.formCopy;
      }
    },
    computedIndate(item) {
      const temp = `${item.startTime}至${item.endTime}`;
      return temp;
    },
    dictCouponType(type) {
      const typeOption = [
        { label: '体验券', value: 1 },
        { label: '优惠券', value: 2 },
        { label: '转赠券', value: 3 },
        { label: '礼包', value: 4 },
        { label: '充值劵', value: 5 },
      ];
      return typeOption.filter((item) => {
        return item.value === +type;
      })[0].label;
    },
    filterMemberCoupon() {
      this.currentCouponRecords = [];
      if (this.currentCouponRecordsBack.length == 0) return;
      //
      for (let i = 0; i < this.currentCouponRecordsBack.length; i++) {
        const item = this.currentCouponRecordsBack[i];
        const datestr = moment(new Date()).format('YYYY-MM-DD');
        if (item.dayFlag == 0 && item.startTime == datestr) {
          console.log(`当天不可用：${datestr}`);
          continue;
        }
        if (this.isHoliday && item.holidayFlag == 0) {
          console.log(`节假日不能使用：${item.name}`);
          continue;
        }
        if (item.types !== 5) continue;
        // 过期的不能用
        if (item.endTime < datestr) {
          continue;
        }
        this.currentCouponRecords.push(item);
      }
    },
    handleChooseCurrentCoupon(item) {
      const that = this;
      let MaxShareCnt = 99999;
      let bFind = false;
      for (let i = 0; i < this.selectCoupons.length; i++) {
        const tmp = this.selectCoupons[i];
        if (item.id == tmp.id) {
          bFind = true;
          this.$set(item, 'checked', false);
          this.selectCoupons.splice(i, 1);
          break;
        }
      }
      if (this.selectCoupons.length == 0 && !bFind) {
        // 判断使用条件
        if (item.conditioncash > 0 && this.form.amount < item.conditioncash) {
          this.$message.warning(`该充值劵满${item.conditioncash}才可以使用`);
          return;
        }
        this.$set(item, 'checked', true);
        this.selectCoupons.push(item);
      } else {
        if (!bFind) {
          // 需要添加，则需要看看叠加次数是否超过,找到最小的。
          for (let i = 0; i < this.selectCoupons.length; i++) {
            const tmp = this.selectCoupons[i];
            if (tmp.sharecnt < MaxShareCnt) MaxShareCnt = tmp.sharecnt;
          }
          if (
            this.selectCoupons.length < MaxShareCnt
            && item.sharecnt > this.selectCoupons.length
          ) {
            // 判断使用条件
            if (
              item.conditioncash > 0
              && this.form.amount < item.conditioncash
            ) {
              this.$message.warning(
                `该充值劵满${item.conditioncash}才可以使用`
              );
              return;
            }
            this.$set(item, 'checked', true);
            this.selectCoupons.push(item);
          } else this.$message.warning('已超过叠加次数，不能同时使用');
        }
      }
      //
      this.form.couponids = '';
      this.CouponSum = 0;
      for (let i = 0; i < this.selectCoupons.length; i++) {
        var item = this.selectCoupons[i];
        this.CouponSum += item.cash;
        if (that.form.couponids === '') that.form.couponids = item.id;
        else that.form.couponids = `${that.form.couponids},${item.id}`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.aaa {
  width: 92px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  right: 5px;
}

.bbb {
  background-color: #409dff;
  text-align: center;
  font-size: 13px;
  font-weight: 450;

  color: #fff;
  word-spacing: -5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 0;
  left: 8px;
  top: 15px;
  width: 120px;
}

.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.couponCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  max-height: 400px;
  overflow: auto;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 300px;
  margin-right: 20px;
  height: 110px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid red;
  border-radius: 10px;
  margin-bottom: 20px;
  .bbb {
    background: red;
  }
  .left {
    border-radius: 7px 0 0 7px;
    width: 100px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .Title_name {
      font-size: 16px;
      line-height: 24px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.type-5 {
  border: 2px solid #409dff;
  .left {
    background: #ffd306;
  }
  .bbb {
    background: #ffd306;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}
</style>
